import { type ActionFunctionArgs, redirect } from '@remix-run/node'
import { CardContent, CardHeader, CardTitle } from '@repo/ui/components/Card.js'
import { LogoIcon } from '@repo/ui/components/Logo.js'
import { LoginButton } from '~/components/LoginButton'
import { authenticator } from '~/services/auth/auth.server'
import { actionResponse, validateFormData } from '~/utils/actions.server'
import { setCookie } from '~/utils/cookie.server'
import { LoginSchema } from './routeConfig'

export const action = async ({ request }: ActionFunctionArgs) => {
  const submission = await validateFormData(request, LoginSchema)
  if (submission.status !== 'success') return actionResponse.error(submission.reply())
  const { url, codeVerifier } = await authenticator.generateAuthUrl(submission.value.provider)
  const headers = new Headers()
  headers.append('set-cookie', await setCookie('timezone', submission.value.timezone))
  headers.append('set-cookie', await setCookie('authCodeVerifier', codeVerifier))
  return redirect(url, { headers })
}

export default function LoginPage() {
  return (
    <>
      <CardHeader className="flex flex-col items-center justify-center gap-2">
        <LogoIcon className="size-8" />
        <CardTitle className="text-xl font-bold">Log in or sign up</CardTitle>
      </CardHeader>
      <CardContent className="space-y-3">
        <LoginButton provider="google" />
        <LoginButton provider="microsoft" />
        <small className="text-muted-foreground text-center w-full block">
          Please use your work email account.
        </small>
      </CardContent>
    </>
  )
}
